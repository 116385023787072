import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg7 from '../assets/img/image7.png'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article6 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS attending the WWT Innovation Smart Water Conference 2024              
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 29/03/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg7} title=""  width="520" height="291" />
               <p style={{textAlign:'justify'}}>Our TUBERS partner, <a id='hyperlink' href='https://www.alsymex-alcen.com/'>Alsymex</a>, represented by Joel vanden Bosch, 
               attended the <a id='hyperlink' href='https://utilityweek.co.uk/event/wwt-innovation-and-smart-water-conference-2024/'>WWT Innovation and Smart Water Conference</a> organised by 
               <a id='hyperlink' href='https://utilityweek.co.uk/'> Utility Week Group</a> in Birmingham on
March 14, 2024. The 8th annual event hosted water and tech industry delegates and sparked
interesting discussions about technologies, resource management, and current challenges
regarding water sustainability. Therefore, the conversations around our TUBERS project with
fellow innovators and water industry professionals were many and fruitful.</p>
<p style={{textAlign:'justify'}}>
Water leakages in the drinking water pipe distribution in the UK are not just a challenge but a
pressing and high-priority concern. While considerable progress has been made, thanks to
emerging technological innovations, the situation remains urgent. This underscores the
importance of our TUBERS project, which aims to develop soft modular robotic tools for pipeline
inspection and repair to address this critical issue.
</p>
<p style={{textAlign:'justify'}}>
Overall, the different sections, from “The Evolution of Smart Water” to "Data, AI" Machine
Learning” and “Delivering for the Future Customer,” touched upon multiple aspects of water
management, preservation efforts, and the existing/potential implementation of intelligent
systems, all in service of water, the same way as our TUBERS project aims to develop and
introduce soft modular robotic tools for pipeline inspection and repair.</p>
<p style={{textAlign:'justify'}}>For more information on the event, check out the pdf attached below.</p>

<ul style={{float:'left',listStyle:'none',paddingLeft:'0'}}>
              <li style={{padding:'10px'}}><a style={{color:'#1da4b2',textDecoration:'none'}} href={pdffile} download="WWT Innovation and Smart Water delegate guide 2024.pdf"><img style={{width:'22px'}} src={icon}></img> WWT Innovation and Smart Water delegate guide 2024 </a></li>
              </ul>
              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article6