import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import blogimg1 from '../assets/img/utility.jpg'
import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article11 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS project joins the UK Utility Week 2024    
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 28/05/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="420" height="291" />
               <p style={{textAlign:'justify'}}>On May 21-22, 2024, TUBERS attended the Utility Week Congress in Birmingham, UK. The
event is one of the largest ones in the water industry dedicated to UK utility companies,
including water (sewage and drinking water), energy (gas and low-carbon alternatives), and
other relevant sectors.
</p>
<p style={{textAlign:'justify'}}>
In the UK, over 4 million km of pipes and cables are buried, and a hole is dug every 7 seconds,
with circa 60,000 accidental strikes per year. As expected, the estimated economic costs of the
accidental strikes are immense, reaching £ 2.4 B a year. Thus, for the planner and excavator
teams, it takes an average of 6.1 days to request, receive, and prepare the data for a single
project.</p>
<p style={{textAlign:'justify'}}>
Water companies are in dire need of technological solutions to improve the state of the drinking
water pipe distribution networks. The issue of leakage and networking monitoring has not been
adequately explored, and the existing progress is insufficient to address the risks of leakage in
the best possible way. This is where TUBERS' work becomes crucial.
</p>
<p style={{textAlign:'justify'}}>
During the event, our partner Alsymex had the opportunity to discuss the TUBERS scalable and
modular robotic system for water pipe inspection and repair, as well as find out how it could
serve the imperative needs of the water industry in the future.
</p>
<p style={{textAlign:'justify'}}>
We hope to see all the industry experts at the Utility Week Congress 2025 and take our current
discussion to the next level.
</p>
<p style={{textAlign:'justify'}}>
For more information about the event, click below:<br/>
<a id='hyperlink' href='https://www.utilityweeklive.co.uk/content-programme-2024'>https://www.utilityweeklive.co.uk/content-programme-2024</a>
</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article11